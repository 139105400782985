import revive_payload_client_ussWnfMr0m from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_olb73kn2bpbo7sj46aihhgwfda/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_STP8x7T5Zs from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_olb73kn2bpbo7sj46aihhgwfda/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7v9LB4bbRI from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_olb73kn2bpbo7sj46aihhgwfda/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_YtZ2ZVx6yN from "/app/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.4_@types+node@20.12.2_sas_ezjm47um2xo7ajpiagou53mbzi/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_bFXNTwFm8H from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_olb73kn2bpbo7sj46aihhgwfda/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_odkwmE6BmV from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_olb73kn2bpbo7sj46aihhgwfda/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_n9eI2VSJbj from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_olb73kn2bpbo7sj46aihhgwfda/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Ek5q4JiY3R from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_olb73kn2bpbo7sj46aihhgwfda/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_dAwDBFGAFk from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.4.32_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/packages/kiwi-user/.nuxt/components.plugin.mjs";
import prefetch_client_IW7DNPUPYq from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_olb73kn2bpbo7sj46aihhgwfda/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vbGtpVEOBr from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.0_encoding@0.1.13_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+_tszvxllag2y7nbjhymiko36mlq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import siteConfig_wVcznKc4ON from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.6_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.4_@types+node@20._bsdpr6y4lpkr4mn4hotmtopnyy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_KKp2T1kw2B from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.6_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.4_@types+node@20._bsdpr6y4lpkr4mn4hotmtopnyy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_client_3raFxC0VyJ from "/app/node_modules/.pnpm/nuxt-gtag@2.0.6_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import rollbar_XnPIYyOzed from "/app/packages/kiwi-user/plugins/rollbar.ts";
export default [
  revive_payload_client_ussWnfMr0m,
  unhead_STP8x7T5Zs,
  router_7v9LB4bbRI,
  _0_siteConfig_YtZ2ZVx6yN,
  payload_client_bFXNTwFm8H,
  navigation_repaint_client_odkwmE6BmV,
  check_outdated_build_client_n9eI2VSJbj,
  chunk_reload_client_Ek5q4JiY3R,
  plugin_vue3_dAwDBFGAFk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IW7DNPUPYq,
  plugin_vbGtpVEOBr,
  siteConfig_wVcznKc4ON,
  inferSeoMetaPlugin_KKp2T1kw2B,
  plugin_client_3raFxC0VyJ,
  rollbar_XnPIYyOzed
]